<template>
	<div class="details">
		<Commshop
		 :proData="transmitData"
		  />
		
		<div class="conten_body">  
			<div class="conten_left">
				
            
         <!-- 为什么选择万方？万方有什么优势？ -->
				<div class="con_list">
					<img class="" src="@/assets/images/services/3-1/1 (1).jpg">
					<img class="" src="@/assets/images/services/3-1/1 (2).jpg">
					<img class="" src="@/assets/images/services/3-1/1 (3).jpg">
					<img class="" src="@/assets/images/services/3-1/1 (4).jpg">
					<img class="" src="@/assets/images/services/3-1/1 (5).jpg">
				
					
				</div>
				
				
			</div>
			<!-- right -->
			<Phone/>
			

		</div>
	</div>
</template>

<script type="text/javascript">
import Phone from "@/components/Phone.vue"
import Commshop from "@/components/Commshop.vue"
export default{
	components:{
      Phone,
      Commshop
	},
data(){
	return{
		transmitData:{
			title:"安全巡检服务",
			content:"周期性检查与持续性的改进过程，流程化的巡检服务能提高工作效率，标准化的巡检服务能提供准确的信息安全数据，与国际先进信息安全管理和技术保持信息基本同步。",
            cc:"周期性 流程化 标准化 先进性",
			price:'在线咨询',
			url:require('../../assets/images/services/3-1/184_G_1530244590155.jpg')
		}
	}
},
mounted(){

},
methods:{
	

}


};
</script>

<style type="text/css" lang="less" scoped>
.details{
	width:1200px;
	margin:0 auto;

//**/
.conten_body{
	margin-top:30px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
}
.nav_list{
	height:39px; 
   border-bottom: 1px solid #CFCDCD;
}
.nav_list li{
	float:left;
	width:120px;
	text-align:center;
}
.nav_list li a{
	display: inline-block;
	height:39px;
	font-size: 15px;
	line-height: 39px;
	color: #007CC6;
}
.nav_list li  .a_active{
   color:#007CC6;
   border-bottom:1px solid #007CC6;
}
.con_list{
	// padding:0 35px;
	overflow:hidden;
}
.con_list img{
	width:100%;
}





}


</style>







